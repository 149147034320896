<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <app-card-code
        :title="`${$t('menu.nomenclador')} / ${$t('menu.hotels')} / ${$t('menu.operador')}`"
      >
        <OperadorList></OperadorList>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'

import OperadorList from './tablas/OperadorList.vue'

export default {
  components: {
    AppCardCode,
    OperadorList,
  },
}
</script>
